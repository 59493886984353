(function($) {
	$(function() {
		$(".question-wrapper").on('click', function() {
			$(this).siblings().find('.viewMore').addClass('collapsed');
			$(this).siblings().find('.collapse').removeClass('in');
		});
		var loginAsGuest = document.querySelector('a[aria-controls="checkout-guest-form"]');

	    if (typeof(loginAsGuest) != 'undefined' && loginAsGuest != null)
	    {
	        var passwordElem = document.getElementsByClassName('form-informations')[0];
	        passwordElem.style.display = 'none';
	        passwordElem.nextElementSibling.style.display = 'none';
	    }

	})
})(jQuery);